<template>
  <div class="page">
    <van-nav-bar
      title="个人需求"
      ref="navbar"
      left-arrow
      @click-left="back()"
    />
    <div class="person">
      <van-row>
        <van-col span="10">
          <van-image
            width="63"
            height="83"
            :src="STATIC_URL + photoPath"
          ></van-image>
        </van-col>
        <van-col span="14" class="text">
          <div>{{ personMsg }}</div>
          <van-button
            size="mini"
            icon="plus"
            type="primary"
            :color="COLOR"
            @click="certify"
            >修改个人信息</van-button
          >
        </van-col>
      </van-row>
    </div>
    <div class="item">
      <van-row>
        <van-col span="12" class="title"
          >择偶条件 <van-tag plain color="#ff554d">必填</van-tag></van-col
        >
        <van-col span="12" class="btn"
          ><van-button
            plain
            size="mini"
            type="primary"
            :color="COLOR"
            @click="love"
            >编辑</van-button
          ></van-col
        >
      </van-row>
      <van-row>
        <van-col span="24" class="desc">{{ personLoveMsg }}</van-col>
      </van-row>
    </div>
    <Share ref="share" />
  </div>
</template>
<script>
import { Icon, Tag, Uploader, Image } from 'vant'
import Share from '../../common/Share.vue'
export default {
  components: {
    Share: Share,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Uploader.name]: Uploader,
    [Image.name]: Image
  },
  data () {
    return {
      code: '',
      loginState: 'N',
      personCode: '',
      inviterCode: '',
      group: {},
      photoImages: [],
      photoPath: '',
      personMsg: '个人资料,请点击编辑进行修改',
      personLoveMsg: '择偶条件信息,请点击编辑修改'
    }
  },
  mounted () {
    var query = this.$route.query
    var personCode = query.personCode
    this.personCode = personCode
    this.retrievePerson()
    this.$refs.share.default('main')
  },
  methods: {
    initMatch () {
      var editMatch = window.sessionStorage.getItem('edit_match')
      var matchs = JSON.parse(editMatch)
      matchs.forEach(element => {
        this.group[element.group] = 'Y'
      })
    },
    love () {
      this.$router.push({ path: '/mde/people/person/dmdLove', query: { personCode: this.personCode } })
    },
    back () {
      var url = window.sessionStorage.getItem('people_person_back_url')
      window.location.href = url
    },
    async retrievePerson () {
      var pd = { code: this.personCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePerson', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.photoPath = res.data.photoPath
        this.personMsg = res.data.name + ' | ' + res.data.genderName + ' | ' +
          res.data.nationName + ' | ' + res.data.phone + ' | ' + res.data.identity
      }
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  padding-bottom: 49px;
  .person {
    padding: 10px;
    border-bottom: 1px solid #eee;
    .tips {
      text-align: left;
      height: 20px;
      line-height: 20px;
    }
    .text {
      padding-top: 10px;
      text-align: center;
      height: 20px;
      line-height: 20px;
    }
  }
  .item {
    padding: 20px;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    .title {
      font-size: 15px;
      height: 20px;
      line-height: 20px;
      font-weight: 600;
      margin: 10px 0px 10px 0px;
      padding-left: 10px;
      text-align: left;
    }
    .desc {
      text-align: left;
      padding-left: 10px;
    }
    .btn {
      height: 20px;
      line-height: 20px;
      margin: 10px 0px 0px 0px;
      padding-right: 10px;
      text-align: right;
    }
  }
}
.operate {
  padding: 5px 0px;
  background-color: #fff;
  height: 40px;
  position: fixed;
  width: 100%;
  display: flex;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  bottom: 0px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #bbb;
}
</style>
